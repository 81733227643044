export default function initStats() {
  const scrollCounters = document.querySelectorAll(".stat-number span");

  // console.log(scrollElements);

  const elementInView = (el, dividend = 1) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) / dividend
    );
  };

  // const elementOutofView = (el) => {
  //   const elementTop = el.getBoundingClientRect().top;

  //   return (
  //     elementTop > (window.innerHeight || document.documentElement.clientHeight)
  //   );
  // };

  const displayScrollElement = (counter) => {
    counter.innerText = "0";
    counter.setAttribute("data-count", 0);
    counter.classList.add("started");
    const updateCounter = () => {
      const target = Number(counter.getAttribute("data-value"));
      const count = Number(counter.getAttribute("data-count"));

      //      const count = Number(counter.innerText);

      const increment = target / 200;
      if (count < target) {
        counter.setAttribute("data-count", `${Math.ceil(count + increment)}`);
        counter.innerText = numberWithCommas(count);
        setTimeout(updateCounter, 30);
      } else {
        counter.innerText = numberWithCommas(target);
        //counter.classList.add("finished");
      }
    };
    updateCounter();
  };

  const numberWithCommas = (x) => {
    return x.toLocaleString();
  };

  // const hideScrollElement = (element) => {
  //   // element.classList.remove("finished");
  // };

  const handleScrollAnimation = () => {
    scrollCounters.forEach((el) => {
      if (!el.classList.contains("started")) {
        if (elementInView(el, 1.25)) {
          displayScrollElement(el);
        }
        //  else if (elementOutofView(el)) {
        //   hideScrollElement(el);
        // }
      }
    });
  };

  handleScrollAnimation();

  window.addEventListener("scroll", () => {
    handleScrollAnimation();
  });
}

export { initStats };
