/* eslint-disable no-unused-vars */
import Swiper from "swiper";
import {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Thumbs,
} from "swiper/modules";
// import Swiper and modules styles

export default function initSliders() {
  const homeHeroSwiper = new Swiper(".home-hero-slider", {
    modules: [Navigation, EffectFade, Autoplay],
    //    slidesPerView: 1,
    loop: true,
    lazy: true,
    effect: "fade",
    spaceBetween: 0,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    wrapperClass: "home-hero-slider__slides",
    slideClass: "home-hero-slider__slide",
    pagination: false,
    navigation: false,
  });

  const caseStudySliders = document.querySelectorAll(".case-studies-slider");

  caseStudySliders.forEach((item) => {
    const caseStudySlider = new Swiper(item, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 10,
      //loop: true,
      lazy: true,
      autoHeight: false,
      wrapperClass: "case-studies-slider__slides",
      slideClass: "case-studies-slider__slide",
      pagination: {
        el: ".case-studies-slider__pagination-bullets",
        clickable: true,
        type: "bullets",
        bulletActiveClass:
          "case-studies-slider__pagination-bullets__bullet--active",
        bulletClass: "case-studies-slider__pagination-bullets__bullet",
        bulletElement: "div",
      },
      navigation: false,
    });
  });

  // const factsSliders = document.querySelectorAll(".facts-slider");

  // factsSliders.forEach((item) => {
  //   const factSlider = new Swiper(item, {
  //     modules: [Autoplay],
  //     slidesPerView: 4,
  //     spaceBetween: 30,
  //     loop: true,
  //     autoplay: {
  //       delay: 2500,
  //       disableOnInteraction: false,
  //     },
  //     wrapperClass: "facts-slider__slides",
  //     slideClass: "facts-slider__slide",
  //     pagination: false,
  //     navigation: false,
  //   });
  // });

  // const factsSwiper = new Swiper('.facts-slider', {
  //   modules: [Autoplay],
  //   slidesPerView: 4,
  //   spaceBetween: 30,
  //   loop: true,
  //   autoplay: {
  //     delay: 1000,
  //     disableOnInteraction: false,
  //   },
  //   wrapperClass: 'facts-slider__slides',
  //   slideClass: 'facts-slider__slide',
  // });

  // const quotesSwiper = new Swiper(".quotes-slider", {
  //   modules: [Navigation, Pagination],
  //   slidesPerView: 1,
  //   // loop: true,
  //   // lazy: true,
  //   //effect: 'fade',
  //   spaceBetween: 0,
  //   // autoplay: {
  //   //   delay: 3500,
  //   //   disableOnInteraction: false,
  //   // },
  //   wrapperClass: "quotes-slider__slides",
  //   slideClass: "quotes-slider__slide",
  //   pagination: {
  //     el: ".quotes-slider__pagination",
  //     clickable: true,
  //     type: "bullets",
  //     bulletActiveClass: "quotes-slider__pagination__bullet--active",
  //     bulletClass: "quotes-slider__pagination__bullet",
  //     bulletElement: "div",
  //   },
  //   navigation: false,
  // });
}
