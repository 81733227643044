import initVue from "./vue/init";
import initMenu from "./main-menu";
import initSliders from "./sliders";
import animationsV2 from "./animationsv2";
import initStats from "./stats";
import initCookieAccept from "./cookie-accept";

import { Fancybox } from "@fancyapps/ui";

function ready(fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

ready(() => {
  initVue();
  initMenu();
  initSliders();
  initCookieAccept();
  animationsV2();
  initStats();

  // used for modal videos in services section
  Fancybox.bind("[data-fancybox]", {});

  // Home hero slideshow.

  const heroSlider = document.getElementById("home-hero-content-slider");

  if (typeof heroSlider !== "undefined" && heroSlider != null) {
    const arr = heroSlider.getElementsByClassName(
      "home-hero-content-slider__content",
    );

    const fadeComplete = function () {
      console.log("loaded1");
      heroSlider.appendChild(arr[0]);
    };

    for (let i = 0; i < arr.length; i++) {
      arr[i].addEventListener("animationend", fadeComplete, false);
    }
  }

  // var full_screen_video = document.querySelectorAll(".full-screen-video");
  // var video_play_buttons = document.querySelectorAll(".play_video");

  // if (video_play_buttons !== null) {
  //   video_play_buttons[0].addEventListener(
  //     "click",
  //     // eslint-disable-next-line no-unused-vars
  //     function (event) {
  //       if (
  //         full_screen_video[0].classList.contains("full-screen-video--show")
  //       ) {
  //         full_screen_video[0].classList.remove("full-screen-video--show");
  //         console.log("remove");
  //       } else {
  //         full_screen_video[0].classList.add("full-screen-video--show");
  //         console.log("add");
  //       }
  //     },
  //     false,
  //   );
  // }

  // var close_button = document.querySelectorAll(
  //   ".swiper-container__close-button",
  // );
  // if (close_button !== null) {
  //   close_button[0].addEventListener(
  //     "click",
  //     function (event) {
  //       if (
  //         full_screen_gallery[0].classList.contains(
  //           "property-swiper-gallery--show",
  //         )
  //       ) {
  //         full_screen_gallery[0].classList.remove(
  //           "property-swiper-gallery--show",
  //         );
  //         console.log("remove");
  //       } else {
  //         full_screen_gallery[0].classList.add("property-swiper-gallery--show");
  //         console.log("add");
  //       }
  //     },
  //     false,
  //   );
  // }
});
