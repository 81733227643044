<template>
  <div class="posts-search animate fade-up">
    <div class="container">
      <div class="posts-search__form">
        <div class="form">
          <div class="form__col1">
            <input
              class="keywords"
              type="text"
              placeholder="Enter keyword"
              v-model="params.search"
            />
          </div>
          <div class="form__col2">
            <a
              class="button button--search"
              :disabled="loading"
              @click="filterPosts"
              >Search</a
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="total == 0 && !loading" class="posts-search__no-results">
      Sorry, there are no articles that match the above criteria.
    </div>

    <TransitionGroup
      name="stagged-fade"
      tag="div"
      class="posts-search__results"
    >
      <template v-for="(post, index) in posts" :key="index">
        <a :href="post.link" :data-index="index" class="posts-search__post">
          <div class="post-panel animate fade-in">
            <div class="image-container">
              <img
                v-if="post.imageSrc"
                :src="post.imageSrc"
                :srcset="post.imageSrcset"
                alt=""
              />
              <div v-else class="default-image"></div>
            </div>
            <div class="content-title-container">
              <h2>{{ post.title }}</h2>
              <div class="link">view article</div>
            </div>
          </div>
        </a>
      </template>
    </TransitionGroup>

    <div v-if="loading" class="loading-spinner"></div>

    <div class="posts-search__dividing-line mt"></div>

    <div v-if="totalPages > 1" class="pagination-wrapper">
      <searchPagination
        v-if="total > 0"
        v-model="params.page"
        :page-count="totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="paginationCallback"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination'"
        :page-class="'pagination__item'"
        :next-link-class="'pagination__next'"
        :prev-link-class="'pagination__prev'"
      ></searchPagination>
    </div>
  </div>
</template>

<script>
import SearchPagination from "./SearchPagination.vue";
import { animationsV2 } from "../../animationsv2.js";
export default {
  components: {
    SearchPagination,
  },
  data() {
    return {
      defaultPerPage: 12,
      posts: [],
      categories: [],
      params: {
        page: 1,
        per_page: 12,
        orderby: "date",
        order: "desc",
        search: "",
      },
      keywords: "",
      selectedCategories: "0",
      loading: false,
      total: 0,
      totalPages: 0,
      staggerDelay: 50,
    };
  },
  mounted() {
    this.getCategories();
    this.filterPosts();
  },
  computed: {
    showMore() {
      return this.params.page < this.totalPages || this.loading;
    },
  },
  methods: {
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    async getCategories() {
      this.loading = true;

      await this.axios.get("categories", { perPage: -1 }).then((response) => {
        this.categories = response.data;
      });

      this.loading = false;
    },

    async getPosts() {
      this.loading = true;

      const params = { ...this.params };

      await this.axios
        .get("posts", {
          params,
        })
        .then((response) => {
          const posts = [];

          response.data.forEach((post) => {
            posts.push({
              title: post.title.rendered,
              link: post.link,
              imageSrc: post.featured_image_src,
              imageSrcset: post.featured_image_srcset,
            });
          });
          this.posts = this.posts.concat(posts);
          this.total = parseInt(response.headers["x-wp-total"], 10);
          this.totalPages = parseInt(response.headers["x-wp-totalpages"], 10);
        });

      animationsV2();
      this.loading = false;
    },
    getMorePosts() {
      if (this.showMore) {
        this.params.page += 1;

        this.params.per_page = this.defaultPerPage;
        this.getPosts();
      }
    },
    filterPosts(categoryID = 0) {
      this.posts = [];
      this.params.page = 1;

      if (this.isNumeric(categoryID)) {
        this.selectedCategories = categoryID;
      }

      if (this.selectedCategories !== 0) {
        this.params.categories = this.selectedCategories;
      } else {
        delete this.params.categories;
      }

      this.params.per_page = this.defaultPerPage;
      delete this.params.offset;
      this.getPosts();
    },
    filterPaginationPosts() {
      this.posts = [];
      this.getPosts();
    },
    clear() {
      this.selectedCategories = "0";
      this.keywords = "";
      this.params = { ...this.default_params };
      this.filterPosts();
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    onEnter(el) {
      const delay = el.dataset.index * this.staggerDelay;
      setTimeout(() => {
        el.style.opacity = 1;
      }, delay);
    },
    onLeave(el) {
      el.style.display = "none";
    },
    paginationCallback(pageNumber) {
      this.params.page = pageNumber;

      // history.pushState(
      //   this.params.page,
      //   'null',
      //   window.location.pathname + '?pl=' + this.params.page,
      // );

      // // Jump to search results.
      // let top = document.getElementById('results-top').offsetTop;
      // window.scrollTo(0, top - 300);

      this.filterPaginationPosts();
    },
  },
};
</script>
